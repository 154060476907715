

export const catEstimatedToTooltip = (catEstimated: number) => {
  switch (catEstimated) {
    case 1:
      return 'Very Likely Produced'
      break
    case 2:
      return 'Likely Produced'
      break
    case 3:
      return 'Possibly Produced'
      break
    case 4:
      return 'Likely Privileged'
      break
    case 5:
      return 'Very Likely Privileged'
      break
    default:
      return null
  }
}

export const codeToColor = (catEstimated: number) => {
  switch (catEstimated) {
    // Prod
    case 1:
      return '#008000'
      break
    case 2:
      return '#009999'
      break
    case 3: 
      return '#e6e600'
      break
    // Priv
    case 4:
      return '#ff9900'
      break
    case 5:
      return '#ff0000'
      break
    default:
      return null
  }
}

export function getBaseUrl(env: string) {
    switch (env) {
      case 'local':
        return 'http://localhost:3000'
      case 'development':
        return 'https://dg-dev-develop.discoverygenie.com'
      case 'staging':
        return 'https://app-cil.discoverygenie.com'
      case 'demo':
        return 'https://app-dg-demo.discoverygenie.com'
      case 'production':
        return 'https://app.discoverygenie.com'
      default:
        throw new Error(JSON.stringify('No environment specified'))
    }
  }

export const formatBytes = (bytes: number, decimals?: number) => {
  const k = 1024
  const dm = decimals || 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  if (bytes === 0) return '0 Bytes'
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const currencyFormat = (num: number) => '$' + (num/100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

export function getFileExtension(filename: string) {
  let arr
  if ((/[.]/.exec(filename)) !== null) {
    arr = /[^.]+$/.exec(filename)
  }

  if (arr !== null && arr !== undefined) {
    return arr[0]
  }
  return 'mbox'  
}

export const removePath = (fullPath: string) => fullPath.replace(/^.*[\\/]/, '');

export const addLeadingZeros = (num: number, length: number) => num.toString().padStart(length, '0');

export function dec2hex (dec: number) {
  return dec.toString(16).padStart(2, "0")
}

export function generateId (len: number) {
  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

export const exSetStatuses: { [key: number]: any } = {
  0: { status: 'Ready to upload', message: 'UPLOAD' },
  11: { status: 'Signed', message: 'PROCESSING' },
  22: { status: 'Signed', message: 'PROCESSING' },
  59: { status: 'Unpaid', message: 'REVIEW'},
  60: { status: 'Processed', message: 'REVIEW' },
  61: { status: 'Finish request', message: 'FINISHING' },
  62: { status: 'Finish request', message: 'FINISHING' },
  70: { status: 'Ready to download', message: 'DOWNLOAD' },
  75: { status: 'Print Ordered', message: 'PRINT ORDERED' },
  110: { status: 'Error finishing exhibit Set', message: 'EXCEPTION' },
}

export const jobStatuses: { [key: number]: any } = {
  0: { status: 'Ready to upload', message: 'UPLOAD' },
  10: { status: 'Client request sent', message: 'REQUEST SENT' },
  11: { status: 'Signed', message: 'PROCESSING' },

  21: { status: 'Zip processing request', message: 'PROCESSING' },
  22: { status: 'Zip processing lock', message: 'PROCESSING' },
  29: { status: 'Zip processed, converting', message: 'PROCESSING' },

  31: { status: 'PST processing request', message: 'PROCESSING' },
  32: { status: 'PST processing lock', message: 'PROCESSING' },
  33: { status: 'Proc units created', message: 'REVIEW PST' },
  34: { status: 'Merge processing request', message: 'PROCESSING' },
  35: { status: 'Merge processing lock', message: 'PROCESSING' },
  36: { status: 'PST converted to mbox', message: 'PROCESSING' },

  41: { status: 'MBOX processing request', message: 'PROCESSING' },
  42: { status: 'MBOX processing lock', message: 'PROCESSING' },
  49: { status: 'MBOX processed, converting', message: 'PROCESSING' },
  50: { status: 'Parsed', message: 'CLASSIFY EMAILS' },
  51: { status: 'Analyzing', message: 'PROCESSING' },

  59: { status: 'Analyzed', message: 'REVIEW' },

  60: { status: 'Analyzed', message: 'REVIEW' },
  61: { status: 'Finish request', message: 'FINISHING' },
  62: { status: 'Finishing', message: 'FINISHING' },

  70: { status: 'Ready to download', message: 'DOWNLOAD' },
  71: { status: 'Reopened', message: 'REOPENED' },
  72: { status: 'Reopened and finish request', message: 'FINISHING' },
  73: { status: 'Reopened and finishing', message: 'FINISHING' },

  80: { status: 'Download expired - cronjob successful', message: 'EXPIRED' },

  91: { status: 'Deleting', message: 'DELETING' },
  92: { status: 'Deleted', messages: 'DELETED'}, // Not visible to user
  93: { status: 'Deleted by dispute', messages: 'DELETED'}, // Not visible to user
  94: { status: 'Deleted by support - cronjob successful', messages: 'DELETED'}, // Not visible to user
  95: { status: 'Deleted by support - cronjob exception', messages: 'DELETED'}, // Not visible to user

  100: { status: 'Error uploading', message: 'EXCEPTION' },
  101: { status: 'Error parsing ZIP', message: 'EXCEPTION' },
  102: { status: 'Error converting PST', message: 'EXCEPTION' },
  103: { status: 'Error merging PST', message: 'EXCEPTION' },
  104: { status: 'Error parsing MBOX', message: 'EXCEPTION' },
  105: { status: 'Error classifying emails', message: 'EXCEPTION' },

  110: { status: 'Error finishing job', message: 'EXCEPTION' },

  120: { status: 'Error deleting job', message: 'EXCEPTION' }, // Not visible to user
  121: { status: 'Error deleting job from dispute', message: 'EXCEPTION' }, // Not visible to user
}
