import React, { useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    LoadingButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from 'shared-components/material/core'
import ErrorModal from '../ErrorModal'
import { CheckboxInput, SelectInput } from 'shared-components/inputs'
import { useError } from 'shared-components/hooks/index'
import { Cost, PayMethod } from 'generated/graphql'
import { CenteredForm } from 'shared-components/layout'
// import { getCreditBalance } from 'api/accounts'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { createExSetPayment, finalizeExSetPayment } from 'api/exSets'


const schema = yup.object({
    selectedSource: yup.string(),
    savePayMethod: yup.boolean(),
}).required()

type FormData = {
    selectedSource: string,
    savePayMethod: boolean,
}

type PaymentProps = { 
    costs: Cost[],
    payMethods: PayMethod[],
    handleNext: () => void,
    handleBack: () => void,
    closePayModal: (event?: object, reason?: string) => void
}

export default function Payment({ 
    costs,
    payMethods,
    handleNext,
    handleBack,
    closePayModal
} : PaymentProps) {
    const stripe = useStripe()
    const elements = useElements()
    const [cardEntered, setCardEntered] = useState(false)
    const [error, href, handleError, resetError ] = useError()
    const [loading, setLoading] = useState(false)
    // const [creditBalance, setCreditBalance] = useState(0)
    // const [applyCredits, setApplyCredits] = useState(false)
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            selectedSource: '',
            savePayMethod: false,
        },
        resolver: yupResolver(schema),
    })

    // const getCreditBalananceFn = async () => {
    //     const payload = await getCreditBalance()
    //         .catch(e => handleError(e))
        
    //     if (payload.success) {
    //         setCreditBalance(payload.data.creditBalance)
    //     } else {
    //         handleError(payload.err, payload.href)
    //     }
    // }

    // useEffect(() => {
    //     getCreditBalananceFn()
    // }, [])

    const { handleSubmit, watch, formState: { errors } } = methods

    const payMethodOptions = payMethods.map(payMethod => {
        const label = `${payMethod.payMethodUserId ? 'User' : 'Firm'} | xxxx-xxxx-xxxx-${payMethod.payMethodUiIdentifier} | exp ${payMethod.payMethodExpDate}`
        return {
            label,
            value: payMethod.payMethodStripeId || '0'
        }
    })

    payMethodOptions.push({label: 'New Payment Method', value: '0'})

    const calculateTotal = () => {
        const costTotal = costs.reduce<number>((acc, cost) => acc + cost.costTotalActual!, 0)

        if (costTotal && costTotal < 50) {
            return 0
        }
        
        return costTotal || 0
    }

    // const calculateAppliedCredits = () => {
    //     const calculatedTotal = calculateTotal()
    //     if (applyCredits) {
    //         if (creditBalance >= calculatedTotal) {
    //             return calculatedTotal
    //         } else if (calculatedTotal >= creditBalance) {
    //             return creditBalance
    //         } else {
    //             return 0
    //         }
    //     } else {
    //         return 0
    //     }
    // }

    // const calculateCostMinusCredits = () => {
    //     const calculatedTotal = calculateTotal()
    //     if (applyCredits) {
    //         if (creditBalance >= calculatedTotal) {
    //              return 0
    //         } else {
    //             return calculatedTotal - creditBalance
    //         }
    //     } else {
    //         return calculatedTotal
    //     }       
    // }

    // const calculateCreditBalanceMinusCreditsApplied = () => {
    //     const calculatedTotal = calculateTotal()
    //     if (applyCredits) {
    //         if (creditBalance >= calculatedTotal) {
    //             return creditBalance-calculatedTotal
    //         } else {
    //             return 0
    //         }
    //     } else {
    //         return creditBalance
    //     }
    // }

    const handleFinish = async (paymentId: number, savePayMethod: boolean) => {
        const payload = await finalizeExSetPayment(costs[0].costExSetId as number, paymentId, savePayMethod) // add savePayMethod and applyCredits
            .catch(e => handleError(e))

        if (payload.success) {
            setLoading(false)
            handleNext()
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    const handleStripe = async (paymentId: number, clientSecret: string, payment_method: string, stripePayMethodId: string, savePayMethod: boolean) => {
        const cardElement = elements && elements.getElement('card')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let stripeData: any
        if (cardElement && stripePayMethodId === '0') { // User entered new card
            stripeData = {
                payment_method: {
                    card: cardElement,
                }
            }
            if (savePayMethod) {
                stripeData.setup_future_usage = 'on_session'
            }
        } else {
            stripeData = { payment_method }
        }

        const payload = stripe && (await stripe.confirmCardPayment(clientSecret, stripeData))

        if (payload && payload.error) {
            handleError(payload && payload.error.message)
            setLoading(false)
        } else {
            handleFinish(paymentId, savePayMethod)
        }
    }

    const createPayment = async (formData: FormData) => {
        const { 
            selectedSource: stripePayMethodId,
            savePayMethod
        } = formData

        const payload = await createExSetPayment(costs[0].costExSetId as number, costs, stripePayMethodId)
            .catch(e => handleError(e))
        if (payload.success) {
            if (payload.data.hasCost) {
                handleStripe(payload.data.payment.paymentId, payload.data.payIntent.client_secret, payload.data.payIntent.payment_method, stripePayMethodId, savePayMethod)
            } else {
                setLoading(false)
                closePayModal()
            }
        } else {
            handleError(payload.err, payload.href)
            setLoading(false)
        }
    }

    const onSubmit = async (formData: FormData) => {
        setLoading(true)
        createPayment(formData)
    }

    const selectedSource = watch('selectedSource')

    const handleChange = (e: StripeCardElementChangeEvent) => e.complete ? setCardEntered(true) : setCardEntered(false)

    const renderPaymentMethod = () => {
        return (
            <div>
                <h3>Select payment method</h3>
                <Box sx={{'& .MuiFormControl-root': { width: '100%' }}}>
                    <SelectInput
                        name="selectedSource"
                        label="Payment Method"
                        options={payMethodOptions}
                        error={errors.selectedSource !== undefined ? true : false}
                        errorMessage={errors.selectedSource ? errors.selectedSource.message : undefined}
                    />
                </Box>
                {selectedSource === '0' ? (
                    <>
                    <p>Enter credit card below</p>
                    <CardElement onChange={handleChange} />
                    <CheckboxInput
                        name='savePayMethod'
                        label={
                            <Typography variant="body1" component="p" textAlign='left'>
                                Save payment method
                            </Typography>   
                        }
                        required
                        error={errors.savePayMethod != undefined ? true : false }
                        errorMessage={errors.savePayMethod ? errors.savePayMethod.message : undefined}
                    />
                </>
                ) : null}
            </div>
        )
    }
    
    if (error.length > 0) {
        return <ErrorModal error={error} href={href} resetError={resetError} />
    }
    
    return (
        <>
            <DialogContent 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    height: "536px",
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                
                <Table style={{maxWidth: '500px' }}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            {/* <TableCell>{creditBalance > 0 ? 'Sub Total' : "Total"}</TableCell> */}
                            <TableCell />
                            <TableCell>{<span>${(calculateTotal() / 100).toFixed(2)}</span>}</TableCell>
                        </TableRow>
                        {/* {creditBalance > 0 && (
                            <>
                                <TableRow>
                                    <TableCell>
                                        Credit Balance: ${(calculateCreditBalanceMinusCreditsApplied()/100).toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        <Button 
                                            size='small'
                                            onClick={() => setApplyCredits(!applyCredits)} 
                                            variant="outlined" 
                                            disabled={calculateTotal() === 0}
                                        >
                                            {applyCredits ? 'Remove' : 'Apply'}
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        ${(calculateAppliedCredits()/100).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell />
                                    <TableCell>
                                        ${(calculateCostMinusCredits()/100).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            </>
                        )} */}
                    </TableBody>
                </Table> 
                <CenteredForm>
                    <FormProvider {...methods}>
                        {
                            (
                                (calculateTotal() > 50) 
                                // && calculateCostMinusCredits() > 50
                            ) 
                            && renderPaymentMethod()
                        }   
                    </FormProvider>
                </CenteredForm>
            </DialogContent>
            <DialogActions>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pt: 2,
                    width: '100%'
                }}>
                    <Button
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    {loading ? (
                        <LoadingButton loading variant="outlined">
                            Next
                        </LoadingButton>
                    ) : (
                        <Button 
                            onClick={handleSubmit(onSubmit)} 
                            variant="contained" 
                            disabled={
                                calculateTotal() > 50 && 
                                selectedSource === '' ||
                                // && calculateCostMinusCredits() > 50 ||
                                (selectedSource === '0' && !cardEntered)
                            }
                        >
                            Submit
                        </Button>
                    )}

                </Box>
            </DialogActions>
        </>
  )
}
