import React, { useEffect, useState } from 'react'
import { RightAlignedForm } from 'shared-components/layout'
import { 
  Typography,
  Button,
  Link,
  Box,
} from 'shared-components/material/core'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { getWholesalePartnerParam } from 'api/accounts'

export default function Guid() {
  const [error, href, handleError, resetError] = useError()
  const [partnerParam, setPartnerParam] = useState('')

  const getWholesalePartnerParamFn = async () => {
    const payload = await getWholesalePartnerParam()
      .catch(e => handleError(e))
    if (payload.success) {
      setPartnerParam(payload.data.partnerParam)
    } else {
      handleError(payload.err, payload.href)
    }
  }

  useEffect(() => {
    getWholesalePartnerParamFn()
  }, [])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '100%',
      }}
    >
      <div />
      <RightAlignedForm>
        <Typography variant='h3' gutterBottom>
          GUID
        </Typography>
        <Typography variant='body1' gutterBottom>
        To connect Exhibit Genie with ServiceLink:<br />
        <ol>
          <li>Log into ServiceLink as a company user.</li>
          <li>Select <strong>Settings &gt; Divisions &gt;</strong> select a division then click on the edit button.</li>
          <li>Click on the <strong>Interfaces</strong> tab.</li>
          <li>Copy and paste the GUID below into the <strong>Exhibit Genie GUID</strong> field.</li>
          <li>Click on <strong>Verify</strong> to ensure the GUID is correct.</li>
        </ol>
        </Typography>
        <Typography variant='body1' gutterBottom>
          <strong>GUID:</strong><br />{partnerParam}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {navigator.clipboard.writeText(`${partnerParam}`)}}
        >
          Copy
        </Button>
      </RightAlignedForm>
      <ErrorModal error={error} href={href} resetError={resetError}/>
    </Box>
  )
}
