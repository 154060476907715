import { Cost } from "generated/graphql"

const { REACT_APP_API_URL } = process.env

export async function postS3ExSetUploadUrl({
    exSetId, 
    jobUploadFilename, 
    jobUploadFilesize, 
  } : {
    exSetId: number, 
    jobUploadFilename: string, 
    jobUploadFilesize: number, 
  }) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/upload`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
      body: JSON.stringify({ jobUploadFilename, jobUploadFilesize }),
    })
  
    return res.json()
  }

export async function finishExSet(exSetId: number, savePayMethod: boolean) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/finish`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
      body: JSON.stringify({ savePayMethod }),
    })
  
    return res.json()
  }

  export async function putExhibitBatch(
    {
      exSetId,
      exhibitIds,
      copyDescriptionToBates,
      exhibitDescription,
      exhibitBates,
      exhibitWitness,
      exhibitNotes,
      exhibitSuppressed,
      exhibitDateString,
    } : {
      exSetId: number,
      exhibitIds: number[],
      copyDescriptionToBates?: boolean,
      exhibitDescription?: string,
      exhibitBates?: string,
      exhibitWitness?: string,
      exhibitNotes?: string,
      exhibitSuppressed?: boolean,
      exhibitDateString?: string
    }
  ) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/exhibit-batch`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
      body: JSON.stringify({ 
        exhibitIds,
        copyDescriptionToBates,
        exhibitDescription,
        exhibitBates,
        exhibitSuppressed,
        exhibitWitness,
        exhibitNotes,
        exhibitDateString,
      }),
    })
  
    return res.json()
  }

  export async function getS3ArchiveUrl(exSetId: number) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/s3-archive-url`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
    })
  
    return res.json()
  }

  export async function calcExSetCost(exSetId: number) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-Sets/${exSetId}/calc-cost`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
    })
  
    return res.json()
  }

  export async function getExSetPayIntentOrFinish(exSetId: number, exSetCostActualClientCalc: number, stripePayMethodId: string) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/get-pay-intent-or-finish`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
      body: JSON.stringify({ exSetCostActualClientCalc, stripePayMethodId }),
    })
  
    return res.json()
  }

  export async function sendToPrinter(exSetId: number) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/send-to-printer`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
    })
  
    return res.json()
  }

  export async function calcUnpaidExSetCosts(exSetId: number) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-Sets/${exSetId}/unpaid-costs`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
    })
  
    return res.json()
  }

  export async function createExSetPayment(exSetId: number, costs: Cost[], stripePayMethodId: string) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/create-payment`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
      body: JSON.stringify({ costs, stripePayMethodId }),
    })
    return res.json()
  }

  export async function finalizeExSetPayment(exSetId: number, paymentId: number, savePayMethod: boolean) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/finalize-payment`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
      body: JSON.stringify({ paymentId, savePayMethod }),
    })
  
    return res.json()
  }

  export async function sendPrintEmail(exSetId: number, customMessage: string) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/send-to-printer`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
      body: JSON.stringify({ customMessage }),
    })
  
    return res.json()
  }

  export async function getPrintEmailFields(exSetId: number) {
    const res = await fetch(`${REACT_APP_API_URL}/api/ex-sets/${exSetId}/printer-email-fields`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-cache',
    })
  
    return res.json()
  }