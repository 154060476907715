/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license-pro'
import Router from './Router'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import reportWebVitals from './reportWebVitals'
import { CookiesProvider } from 'react-cookie'

LicenseInfo.setLicenseKey('828f0598c33ef9fcc24d53c2386706a7Tz03NzMzOSxFPTE3Mjk4MDk5NzYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')

const { REACT_APP_API_URL } = process.env

const themeOptions = {
  palette: {
    primary: {
      main: '#394587',
      light: '#C8CFE4',
      dark: '#1F2547',
    },
    secondary: {
      main: '#fed264',
      light: '#ffe54c',
      dark: '#FEBf20',
    },
    text: {
      primary: '#1F2547',
      disabled: '#40445d',
    },
    grey: {
      50: '#f9f9fa',
      100: '#f3f3f6',
      200: '#eaeaf0',
      300: '#d8dae3',
      400: '#adb0c3',
      500: '#868aa8',
      600: '#585d81',
      700: '#494d6b',
      800: '#323548',
      900: '#191a24',
      A50:  '#f9f9fa',
      A100: '#f3f3f6',
      A200: '#eaeaf0',
      A300: '#d8dae3',
      A400: '#adb0c3',
      A500: '#868aa8',
      A700: '#494d6b',
    }
  },
  typography: {
    h2: {
      fontSize: 24,
      fontWeight: 400,
      color: '#262938',
    },
    h3: {
      fontSize: 20,
      fontWeight: 400,
      color: '#262938',
    },
    body1: {
      color: '#262938',
    }
  },
}

let theme = createTheme(themeOptions)

theme = responsiveFontSizes(theme)

const link = createHttpLink({
  uri: `${REACT_APP_API_URL}/graphql`,
  credentials: 'include',
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: 'network-only',
  //   },
  // },
})


const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = () => {
  if (stripePublicKey) {
    return loadStripe(stripePublicKey)
  }
  return null
}

function Entry() {
  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Elements stripe={stripePromise()}>
            <CookiesProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </CookiesProvider>
          </Elements>
        </ThemeProvider>
      </ApolloProvider>
    </React.StrictMode>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<Entry />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
