import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation, useOutletContext } from 'react-router-dom'
import { io, Socket } from 'socket.io-client'
import {
  Box,
  CssBaseline,
  styled,
  Toolbar,
  useTheme,
  Theme,
  CircularProgress,
} from 'shared-components/material/core'
import { useError, usePageLeave } from 'shared-components/hooks'
import { ErrorModal, WarningModal, Feedback } from 'shared-components/modals'

import { postS3UploadUrl } from 'api/jobs'
import axios from 'axios'
import { getFileExtension } from 'shared-components/utils'
import DrawerContent from './DrawerContent'
import AppBarContent from './AppBarContent'
import { gql, useQuery } from '@apollo/client'
import { CenteredContent } from 'shared-components/layout'
import { postS3ExSetUploadUrl } from 'api/exSets'
import { User } from 'generated/graphql'

export const GET_DATA = gql`
  query GetData {
    user {
      userId
      userEmail
      userFirstname
      userLastname
      userInitials
      userType
      userFirmId
      userTelephone
      userSessionId
      userInternal
      userFirmRole
      userVersionPreference
      userVersionToggleEnabled
      userEmlReviewOrderPref
      userDocReviewOrderPref
      userPartnerId
      userAccountSetupComplete
      userEmailVerified
      userClioSetupComplete
      userRegisteredViaClio
      userSuspended
      userSuspendedAt
      userReinstatedAt
      userCrmKey
      userClioDefaultActivityDescription
      userTourActive
      userJobListOrderPref
      userShowHoldWarning
      userHubspotContactId
      userSearchEnabled
      userExhibitSetsEnabled
      userClioResponse
      userClioRefreshToken
      userShowExGenVideo
    }
    subscription {
      subscriptionId
      subscriptionFirmId
      subscriptionStripeId
      subscriptionPayMethodStripeId
      subscriptionPlanName
      subscriptionAmount
      subscriptionPaymentOption
      subscriptionStatus
      subscriptionAutoRenew
      subscriptionCreatedAt
      subscriptionCanceledAt
      subscriptionExpirationDate
      subscriptionType
      subscriptionTier
      subscriptionInterval
      subscriptionAttorneyCount
    }
    firm {
      firmId
      firmName
      firmCostPerDoc
      firmCostPerIrrel
      firmDiscount
      firmAddress1
      firmAddress2
      firmCity
      firmState
      firmZip
      firmType
      firmTelephone
      firmTrialEnds
      firmSessionTimeoutMins
      firmJobExpirationDays
      firmInternal
      firmCrmKey
      firmReferralTypeId
      firmReferralString
      firmPartnerId
      firmHubspotCompanyId
    }
  }
`

const { REACT_APP_API_URL } = process.env

const DrawerHeader = styled('div')(({ theme } : { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

type FormData = {
  jobId: number, 
  disputeId: number,
  jobUploadFilename: string, 
  jobUploadFilesize: number, 
  allConfidential: boolean, 
  category: string
}

type ExSetFormData = {
  exSetId: number, 
  jobUploadFilename: string, 
  jobUploadFilesize: number, 
}

let socket: Socket

type UploadContextType = { 
  getUploadData: (acceptedFiles: Array<File>, jobId: number, disputeId: number, allConfidential: boolean, category: string) => void,
  getExSetUploadData: (acceptedFiles: Array<File>, exSetId: number) => void,
  fileSize: number, 
  uploadValue: number, 
  uploading: boolean,
  uploadJobId: number | null
  user: User
}

export default function App() {
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [open, setOpen] = React.useState(true)
  const [error, href, handleError, resetError] = useError()
  const [openFeedback, setOpenFeedback] = useState(false)
  const [uploadValue, setUploadValue] = useState(0)
  const [fileSize, setFileSize] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [uploadJobId, setUploadJobId] = useState<number | null>(null)
  const [warning, setWarning] = useState('')
  const [collapse, setCollapse] = useState(false)
  const { data, loading, error: dataError } = useQuery(GET_DATA)
  const { pathname } = useLocation()

  const handleUploadAlert = () => {
    setWarning('Upload in progress, if you refresh the page or close the window/tab your upload will terminate.') 
  }

  usePageLeave(handleUploadAlert, uploading)

  const putUploadToS3 = (file: File, url: string) => {
    return axios({
      url,
      method: 'PUT',
      data: file,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
          setUploadValue(progressEvent.loaded)
      },
    })
      .then(() => {
          setUploading(false)
        })
      .catch(e => handleError(e))
  }

const processUpload = async (data: FormData, file: File) => {
  const payload = await postS3UploadUrl(data)
    .catch(e => handleError(e))
  console.log({data})
  if (payload.success) {
    const { signedPutUrl } = payload.data
    putUploadToS3(file, signedPutUrl)
  }

  return handleError(payload.err, payload.href)
}

const processExSetUpload = async (data: ExSetFormData, file: File) => {
  const payload = await postS3ExSetUploadUrl(data)
    .catch(e => handleError(e))

  if (payload.success) {
    const { signedPutUrl } = payload.data
    putUploadToS3(file, signedPutUrl)
  }

  return handleError(payload.err, payload.href)
}

const getExSetUploadData = useCallback((acceptedFiles: File[], exSetId: number) => {
  const jobUploadFilename = acceptedFiles[0].name
  const jobUploadFilesize = acceptedFiles[0].size
  const extension = getFileExtension(jobUploadFilename)

  if (jobUploadFilesize > 3.5 * 1024 * 1024 * 1024) {
      return handleError("The File size too large. Please upload a file that is 3GB or smaller.")
  }
    
  if (extension !== 'zip') {
      return handleError("Invalid file type. Please upload a ZIP file.")
  }
  setUploadJobId(exSetId)
  setUploading(true)
  setFileSize(jobUploadFilesize)
  const uploadData = { 
    exSetId, 
    jobUploadFilename, 
    jobUploadFilesize
  }
  return processExSetUpload(uploadData, acceptedFiles[0])
}, [])

const getUploadData = useCallback((acceptedFiles: File[], jobId: number, disputeId: number, allConfidential: boolean, category: string) => {
  const jobUploadFilename = acceptedFiles[0].name
  const jobUploadFilesize = acceptedFiles[0].size
  const extension = getFileExtension(jobUploadFilename)

  if (jobUploadFilesize > 3.5 * 1024 * 1024 * 1024) {
      return handleError("The File size too large. Please upload a file that is 3GB or smaller.")
  }
    
  if (extension !== 'mbox' && extension !== 'pst' && extension !== 'zip') {
      return handleError("Invalid file type. Please upload an MBOX, PST, or ZIP file.")
  }
  setUploadJobId(jobId)
  setUploading(true)
  setFileSize(jobUploadFilesize)
  const uploadData = { 
    jobId, 
    disputeId, 
    allConfidential, 
    category, 
    jobUploadFilename, 
    jobUploadFilesize
  }
  return processUpload(uploadData, acceptedFiles[0])
}, [])

  useEffect(() => {
    if ((!socket || !socket.connected) && REACT_APP_API_URL) {
      socket = io(REACT_APP_API_URL, {
        withCredentials: true,
        })

      const timeout = () => {      
        socket.timeout(30000).emit('session-check', (err: {message: string, stack: string}, res: { sessionActive: boolean }) => {
          if (err) {
            // the other side did not acknowledge the event in the given delay
            if (err.message === "operation has timed out") {
              handleError('Error connecting to the server.', '/login')
            } else {
              handleError('An error occurred, please contact support if the error persists', '/login')
            }
          } else if (!res.sessionActive) {
            handleError('Session timed out', '/login')
          }
        })
      }
      const socketInterval = setInterval(timeout, 10000)

      return () => {
        socket.disconnect()
        clearInterval(socketInterval)
      }
    }
  }, [])

  const handleDrawerOpen = () => setOpen(true)

  const handleDrawerClose = () => setOpen(false)

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  const handleCollapse = () => setCollapse(!collapse)

  const handleFeedback = () => setOpenFeedback(true)

  const renderMainBackgroundColor = () => {
    if (pathname.includes('exhibit-set')) {
      return '#F3F6F4'
    }
    if (pathname.includes('document-set') || pathname.includes('document-Set')) {
      return '#F1F3F8'
    }
    return '#efefef'
  }

  if (loading) {
    return <div />
  }

  if (error || dataError && dataError.message) {
    return (
      <ErrorModal 
          error={error || (dataError && dataError.message) || ''}  
          href={href || ''} 
          resetError={resetError}
        />
    )
  }

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarContent 
          handleFeedback={handleFeedback}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerToggle={handleDrawerToggle}
          user={data && data.user}
          subscription={data && data.subscription}
          open={open}
        />
        <DrawerContent 
          uploading={uploading}
          uploadValue={uploadValue}
          fileSize={fileSize}
          collapse={collapse}
          handleCollapse={handleCollapse}
          open={open}
          mobileOpen={mobileOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerToggle={handleDrawerToggle}
          firm={data && data.firm}
        />
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            p: 3, 
            backgroundColor: renderMainBackgroundColor(), 
            minHeight: '100vh', 
            width: 'calc(100vw - 288px)'
          }}>
          <Toolbar sx={{display: { xs: 'block', sm: 'none' }}}/>
          <DrawerHeader 
            theme={theme}
            sx={{display: { xs: 'none', sm: 'block' }}}
          />
          {loading ? (
            <CenteredContent>
              <CircularProgress />
            </CenteredContent>
          ) : (
            <Outlet /* this is a react router feature and renders /app route child components */
              context={{ 
                getExSetUploadData,
                getUploadData, 
                fileSize, 
                uploadValue, 
                uploading, 
                uploadJobId, 
                user: data && data.user,
              }} 
            /> 
          )}
          
        </Box>
        <Feedback openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} />
        <WarningModal warning={warning} />
      </Box>
  )
}

export function useUploadContext() {
  return useOutletContext<UploadContextType>();
}
