import React, { Dispatch, SetStateAction, memo, useState } from 'react'

import { 
    GridToolbarColumnsButton, 
    GridToolbarContainer, 
    GridToolbarDensitySelector, 
    GridToolbarFilterButton, 
} from '@mui/x-data-grid-premium'

import { Box, Button, LoadingButton } from 'shared-components/material/core'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { gql, useMutation } from '@apollo/client'

export const EDIT_USER = gql`
    mutation editUser(
        $userId: Int!, 
        $userJobListOrderPref: JSON, 
    ) {
        updateUser(
            userId: $userId, 
            userJobListOrderPref: $userJobListOrderPref, 
        ) {
            userId
        }
    }
`

function CustomToolbar({
    setOpenAddJob,
    setOpenMergeModal,
    getColumnState,
    userId,
    getSelectedRows,
} : { 
    setOpenAddJob: Dispatch<SetStateAction<boolean>>,
    setOpenMergeModal: Dispatch<SetStateAction<boolean>>,
    getColumnState: () => GridInitialStatePremium,
    userId: number,
    getSelectedRows: () => [],
}) {
    const [editUser, { loading: editUserLoading }] = useMutation(EDIT_USER)
    
    const handleSaveColumnOrder = () => {
        const columnState = getColumnState()
        delete columnState['filter']
        delete columnState['pagination']
        const variables = {userId, userJobListOrderPref: JSON.stringify(columnState)}
        return editUser({ variables })
    }

    const handleMerge = () => setOpenMergeModal(true)

    const selectedRows = getSelectedRows()

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton touchRippleRef={undefined} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector sx={{ mr: 1}} />
                {editUserLoading ? (
                        <LoadingButton loading variant="outlined">
                            Save Columns
                        </LoadingButton>
                    ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={handleSaveColumnOrder}
                        sx={{ ml: 1 }}
                        className="introSaveColumns"
                    >
                        Save Columns
                    </Button>
                    )}

                {selectedRows.length > 1 ? (
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={handleMerge}
                        sx={{ ml: 1 }}
                    >
                        Merge
                    </Button>
                ) : null}
            </GridToolbarContainer>
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenAddJob(true)}
                    size='small'
                >
                    Add Document Set
                </Button>
            </div>
        </Box>
    )
  }

  export default CustomToolbar