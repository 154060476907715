import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import { CircularProgress, Grid, Typography } from 'shared-components/material/core'
import { CenteredContent, DetailContainer } from 'shared-components/layout'
import ErrorModal from 'shared-components/modals/ErrorModal'

import ExhibitGrid from './ExhibitGrid/ExhibitGrid'
import ExSetMeta from 'screens/ExhibitSet/ExSetMeta/ExSetMeta'
import DisputeMeta from 'screens/ExhibitSet/DisputeMeta/DisputeMeta'
import ExhibitGenieSetupPayModal from 'shared-components/modals/ExhibitGenieSetupPayModal/ExhibitGenieSetupPayModal' 

const GET_DATA = gql`
    query ExSet($exSetId: Int, $disputeId: Int) {
        exSet(exSetId: $exSetId) {
            exSetId
            exSetDisputeId
            exSetOwner
            exSetName
            exSetNotes
            exSetPrefix
            exSetStart
            exSetEnd
            exSetCreatedAt
            exSetSequencingType
            exSetStatus
            jobs {
                jobUploadFilename
                jobUploadFilesize
                jobId
                jobStatus
            }
            owner {
                userFirstname
                userLastname
            }
        }
        dispute(disputeId: $disputeId) {
            disputeName
            disputeBatesIntegrity
            disputeMatter
            disputeId
            client {
                clientId
                clientName
            }
            batesRecs {
                batesRecId
                batesRecNextBatesStr
                batesRecNextPrivStr
            }
        }
    }
`

export default function ExhibitSetReview () {
    const { disputeId, exSetId } = useParams()

    const id = disputeId ? parseInt(disputeId) : null
    
    const { loading, error, data } = useQuery(GET_DATA, {
        fetchPolicy: 'network-only',
        variables: { disputeId: id, exSetId: exSetId && parseInt(exSetId) },
    })

    const [openSetupPayModal, setOpenSetupPayModal] = useState(false)

    useEffect(() => {
        if (data && data.exSet.exSetStatus === 59) {
            setOpenSetupPayModal(true)
        }
    }, [data])

    const closePayModal = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return
        }   
        setOpenSetupPayModal(false)
    }

    if (loading) {
        return (
            <CenteredContent>
                <CircularProgress sx={{ color: 'grey.50' }} />
            </CenteredContent>
        )
    }

    if (error) {
        return <ErrorModal error={error.message} />
    }

    return (
        <>
            <Typography variant="h6" noWrap component="h2" sx={{ mt: 0, mb: 1 }}>
                Exhibit Genie
            </Typography>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={6}>
                    <DetailContainer>
                        <ExSetMeta exSet={data.exSet} />
                    </DetailContainer>
                </Grid>
                <Grid item md={6}>
                    <DetailContainer>
                        <DisputeMeta dispute={data.dispute} client={data.dispute.client} />
                    </DetailContainer>
                </Grid>
                <Grid item md={12}>
                    <ExhibitGrid />
                </Grid>
            </Grid>
            {openSetupPayModal && (
                <ExhibitGenieSetupPayModal 
                    open={openSetupPayModal} 
                    closePayModal={closePayModal} 
                    exSetId={exSetId ? parseInt(exSetId) : 0}
                    disputeId={id as number}
                />
            )}
        </>
    )
}


